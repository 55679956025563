.App {
  background-color: #02002466;
  box-shadow: 0 0 15px #000;
}

@media only screen and (min-width : 576px) {
  .App {
    border-radius: 10px;
  }
}
