.Navigation {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.Navbar {
  background-color: #020024cc;
  box-shadow: 0 0 15px #020024;
}

.Nav-icon {
  height: 2.5rem;
}

/* for sm and bigger screens */
@media only screen and (min-width : 576px) {
  .Navbar {
    border-radius: 10px;
  }
  .Navigation {
    padding-top: 15px;
  }
}

/* for md and bigger screens */
@media only screen and (min-width : 768px) {
  .Nav-icon {
    height: 4.5rem;
    position: absolute;
    margin-top: -2.25rem;
  }
  .Nav-brand {
    margin-right: 6rem !important;
  }
}

.Link-icon {
  height: 1.6rem;
  padding-right: 0.2rem;
}

.Nav-current-page {
  color: #e19f27;
  font-weight: bold;
  text-shadow: 0 0 14px #ffe98daa;
}

.Nav-other-page:hover {
  text-shadow: 0 0 14px #b7c8ddaa;
}

.Nav-other-page {
  font-weight: bold;
  color: #b7c8dd;
}