.Md {
  margin-top: 15px;
  padding-top: 15px;
}

.Md-content {
  margin-bottom: 2rem;
}

.Md-content h1, .Md-content h2, .Md-content h3 {
  margin-top: 2rem;
}

.Md-content img {
  max-width: 100%;
}

/* resize google play badge, selected by alt tag */
.Md-content img[alt=google-play-badge] {
  height: 60px;
}

.Md-content p {
  font-size: 1.2rem;
}

.Md-content p>img {
  display: block;
  margin: auto;
  box-shadow: 0 0 15px #000;
}